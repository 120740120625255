









































































































import { Bind, Debounce } from 'lodash-decorators'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Component, Mixins, Ref, Watch } from 'vue-property-decorator'
import shave from 'shave'

import Confirmation from '@/components/modals/Confirmation.vue'
import CourseGroupMonthMixin from '@/mixins/manager/CourseGroupMonthMixin'
import SearchPackageModal from '@/components/modals/packages/SearchPackageModal.vue'
import SmartSearchModal from '@/components/modals/users/SmartSearchModal.vue'
import SwitchInput from '@/components/_uikit/controls/SwitchInput.vue'
import Tag from '@/components/_uikit/Tag.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import UserCard from '@/components/cards/UserCard.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import PermissionsMixin from '@/mixins/PermissionsMixin'
import AuthModule from '@/store/modules/auth'
import DictionaryModule from '@/store/modules/dictionary'
import ManagerOrdersModule from '@/store/modules/manager/orders'
import {
  CourseType,
  ManagerOrderPackageRequest,
  ManagerOrderStoreRequest,
  ManualSaleResource,
  NameValueResource,
  OrderStatus,
  PackageListResource,
  PackageShortResource,
  SearchType,
  SelfResource,
  UserShortResource,
} from '@/store/types'

interface ISelectInfo {
  packages: ManagerOrderPackageRequest[],
  packagesIdsString?: string,
  userID: string,
}

@Component({
  components: {
    Confirmation,
    SearchPackageModal,
    SmartSearchModal,
    SwitchInput,
    Tag,
    TextInput,
    UserCard,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class OrderForm extends Mixins(CourseGroupMonthMixin, NotifyMixin, PermissionsMixin) {
  @Ref() confirm!: Confirmation

  private isMy = false
  private showSmartSearchMasters = false
  private showSearchPackageModal = false
  private searchType = SearchType.BY_ORDERS
  private selectedUser: UserShortResource|null = null
  private overallOldPrice = 0
  private form: ManagerOrderStoreRequest = {
    isManual: true,
    managerId: null,
    packages: [],
    status: OrderStatus.WAIT,
    userId: undefined,
  }
  private discountsList: ManualSaleResource | null = null

  private isShowedAlert = false

  private get groupTypes() {
    const groupTypes: Record<string, string> = {}
    DictionaryModule.groupTypes.forEach(group => groupTypes[group.value] = group.name)
    groupTypes.special = 'Спецкурс'
    return groupTypes
  }

  private get self () {
    return AuthModule.self as SelfResource
  }

  private get orderStatuses () {
    return DictionaryModule.orderStatuses
  }

  private get overallPrice() {
    return this.form.packages.reduce((acc: number, cur: ManagerOrderPackageRequest) => {
      return acc + cur.price
    }, 0)
  }

  private get isPackageLarge() {
    if (this.$vuetify.breakpoint.name === 'xs') {
      return false
    }
    return true
  }

  private get checkEveryCourse() {
    if (this.innerPackages.length)
      return this.innerPackages.every(_package => _package.title === this.innerPackages[0].title)
    return false
  }

  // Триггер запроса на получение скидкок пользователя
  private get triggerGetDiscounts(): string {
    return JSON.stringify({ packages: this.form.packages, userID: this.form.userId })
  }

  // Сумма всех скидок и пакетного предложения
  private get discountSum() {
    return this.discountsList && this.applyDiscount ? (this.discountsList.salePrice ?? 0) + this.packageOfferPriceSum : this.packageOfferPriceSum
  }

  // Сумма пакетных предложений
  private get packageOfferPriceSum() {
    if (this.isSpecial)
      return 0
    const res = this.orderOriginalTotalSum - this.form.packages.reduce((acc: number, cur: ManagerOrderPackageRequest) => acc + cur.price, 0)
    return res <= 0 ? 0 : res
  }

  // Сумма заказа без скидок
  private get orderOriginalTotalSum() {
    return this.innerPackages.reduce((acc: number, cur: PackageShortResource) => acc + (this.applyDiscount && this.isSpecial ? cur.price : cur.originalPrice), 0)
  }

  // Сумма заказа итоговая
  private get orderTotalSum() {
    const salePrice = this.discountsList && this.applyDiscount && this.discountsList.salePrice ? this.discountsList.salePrice : 0
    const res = this.form.packages.reduce((acc: number, cur: ManagerOrderPackageRequest) => acc + cur.price, 0) - salePrice
    return res <= 0 ? 0 : res
  }

  private get isSpecial () {
    return this.innerPackages[0]?.course.type === CourseType.SPECIAL
  }

  private handleOrderStatus(orderStatus: NameValueResource) {
    this.form.status = orderStatus.value as OrderStatus
  }

  private getPackageOldPrice (formPackage: ManagerOrderPackageRequest) {
    const pack = this.innerPackages.find(pack => {
      return pack.id === formPackage.packageId
    }) as PackageShortResource
    return pack.price ? pack.price : 0
  }

  // Применяется ли скидка к заказу
  private get applyDiscount() {
    return Boolean(this.discountsList && this.discountsList.salePrice && this.form.packages.reduce((acc: number, cur: ManagerOrderPackageRequest) => acc + cur.price, 0) === this.innerPackages.reduce((acc: number, cur: PackageShortResource) => acc + cur.price, 0))
  }

  private handleAddPackages (packages: Array<PackageShortResource & PackageListResource>) {
    this.innerPackages = [...packages]
    this.form.packages = packages.map((item: PackageShortResource & PackageListResource) => ({
      packageId: item.id,
      price: item.price,
    }))
    this.form.packages.forEach(element => {
      shave(`#order-package-${element.packageId}`, 32, { spaces: false })
    })
    this.overallOldPrice = 0
    this.form.packages.forEach(pack => this.overallOldPrice += this.getPackageOldPrice(pack))
  }

  private handleAddMaster(user: UserShortResource) {
    this.form.userId = user.id
    this.selectedUser = user
    this.showSmartSearchMasters = false
  }

  private handleDeletePackage (index: number) {
    // this.form.packages.splice(index, 1)
    // this.innerPackages.splice(index, 1)
    // this.overallOldPrice = 0
    // this.form.packages.forEach(item => this.overallOldPrice -= this.getPackageOldPrice(item))
    this.form.packages = this.form.packages.filter(pack => pack.packageId !== index)
    this.innerPackages = this.innerPackages.filter(pack => pack.id !== index)
  }

  private handleDeleteUser() {
    this.form.userId = undefined
    this.selectedUser = null
  }

  private handleIsMyChange (value: boolean) {
    this.form.managerId = value ? this.self.id : null
  }

  private handleEditPrice() {
    if (!this.isShowedAlert && this.applyDiscount) {
      this.confirm.open(
        'Редактирование цены',
        'Если отредактировать цену пакета в поле заказа, то скидка на пакет удалится из заказа. Это действие <strong>нельзя</strong> будет отменить!',
        {
          buttonCancelVisible: false,
          buttonConfirmText: 'Понятно',
          messageNoMargin: true,
          skin: 'primary',
        },
      )
        .then(() => {return})
        .catch(() => {return})
      this.isShowedAlert = true
    }
  }

  @Debounce(500)
  @Bind
  private handleSubmit () {
    if (!this.form.userId) {
      this.notifyError('Выберите мастера')
      return
    }

    if (!this.form.packages.length) {
      this.notifyError('Выберите пакеты')
      return
    }

    if (!this.checkEveryCourse) {
      this.notifyError('Вы не можете создать заказ с пакетами из разных курсов')
      return
    }

    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          ManagerOrdersModule.saveOrder({
            params: {
              ...this.form,
              isManual: this.applyDiscount,
            },
          })
            .then(() => {
              this.notifySuccess('Заказ сохранен')
              this.$emit('success')
            })
            .catch((err) => {
              this.notifyError(err)
            })
        } else {
          this.notifyError('Проверьте введенные данные')
        }
      })
  }

  private getDiscountsCustomOrder() {
    ManagerOrdersModule.getDiscountsCustomOrder({
      packageIds: this.form.packages.map(pack => pack.packageId),
      userId: this.form.userId as unknown as number,
    })
      .then(response => {
        this.discountsList = response
      })
      .catch(this.notifyError)
  }

  private handleDeleteDiscount() {
    this.discountsList = null
  }

  @Watch('triggerGetDiscounts')
  private watchGetDiscounts(value: string, oldValue: string) {
    const current: ISelectInfo = JSON.parse(value)
    const old: ISelectInfo = JSON.parse(oldValue)
    current.packagesIdsString = JSON.stringify(current.packages.map(item => item.packageId))
    old.packagesIdsString = JSON.stringify(old.packages.map(item => item.packageId))
    if (this.form.userId && this.form.packages.length &&
      (current.packages.length !== old.packages.length || current.userID !== old.userID || (current.packages.length === old.packages.length && current.packagesIdsString !== old.packagesIdsString))) {
      this.getDiscountsCustomOrder()
    } else if (!this.form.userId || !this.form.packages.length) {
      this.discountsList = null
    }
  }
}
