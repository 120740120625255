












import { MetaInfo } from 'vue-meta'
import { Component, Mixins } from 'vue-property-decorator'

import OrderForm from '@/components/forms/order/OrderForm.vue'
import NotifyMixin from '@/mixins/NotifyMixin'

@Component({
  components: {
    OrderForm,
  },
})
export default class OrdersNew extends Mixins(NotifyMixin) {
  private get breadcrumbs () {
    return [{ name: 'Заказы', path: '/manager/commerce/orders' }]
  }

  private handleSuccess () {
    this.$router.push({ name: 'manager.commerce.orders' })
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Создание заказа',
    }
  }
}
